<template>
  <div>
    <el-card style="width: 100pc">
      <el-card>
        <el-form :data="e2eCommon" :label-position="orderLabelPosition" inline size="small">
          <el-form-item :label="$t('msg.track.trackingNumber')" prop="mbl">
            <el-input v-model="e2eCommon.trackingNumber" disabled></el-input>
          </el-form-item>
          <el-form-item :label="$t('msg.track.pol')" prop="sealNumber">
            <el-input v-model="e2eCommon.pol"></el-input>
          </el-form-item>
          <el-form-item :label="$t('msg.track.pod')" prop="port" >
            <el-input v-model="e2eCommon.pod" disabled></el-input>
          </el-form-item>
          <el-form-item :label="$t('msg.track.sslCarrier')" prop="sslCarrier">
            <el-input v-model="e2eCommon.sslCarrier" ></el-input>
          </el-form-item>
          <el-form-item :label="$t('msg.track.vessel')" prop="ctnrId">
            <el-input v-model="e2eCommon.vessel"></el-input>
          </el-form-item>
          <el-form-item :label="$t('msg.track.voyage')" prop="ctnrSize">
            <el-input v-model="e2eCommon.voyage"></el-input>
          </el-form-item>
          <el-form-item :label="$t('msg.track.firmsCode')" prop="ctnrSize">
            <el-input v-model="e2eCommon.firmsCode"></el-input>
          </el-form-item>
        </el-form>
        <el-form :data="e2eCommon" :label-position="orderLabelPosition" inline size="small">
          <el-form-item :label="$t('msg.track.etd')" prop="vesselETA">
            <el-date-picker v-model="e2eCommon.etd" style="width: 160px" value-format="YYYY-MM-DD"></el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('msg.track.atd')" prop="vesselETA">
            <el-date-picker v-model="e2eCommon.atd" style="width: 160px" value-format="YYYY-MM-DD"></el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('msg.track.eta')" prop="vesselETA">
            <el-date-picker v-model="e2eCommon.eta" style="width: 160px" value-format="YYYY-MM-DD"></el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('msg.track.ata')" prop="vesselETA">
            <el-date-picker v-model="e2eCommon.ata" style="width: 160px" value-format="YYYY-MM-DD"></el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('msg.track.discharge')" prop="vesselETA">
            <el-date-picker v-model="e2eCommon.discharge" style="width: 160px" value-format="YYYY-MM-DD"></el-date-picker>
          </el-form-item>
        </el-form>
        <el-form :data="drayOrder" :label-position="orderLabelPosition" inline size="small">
          <el-form-item prop="streetOne" :label="$t('msg.address.streetOne')">
            <el-input v-model="drayOrder.streetOne" :disabled="drayCost.ctnrid==='FBA'"></el-input>
          </el-form-item>
          <el-form-item prop="streetTwo" :label="$t('msg.address.streetTwo')">
            <el-input v-model="drayOrder.streetTwo" :disabled="drayCost.ctnrid==='FBA'"></el-input>
          </el-form-item>
          <el-form-item prop="city" :label="$t('msg.address.city')">
            <el-input v-model="drayOrder.city" disabled></el-input>
          </el-form-item>
          <el-form-item prop="state" :label="$t('msg.address.state')">
            <el-input v-model="drayOrder.state" :disabled="drayCost.ctnrid==='FBA'"></el-input>
          </el-form-item>
          <el-form-item prop="zip" :label="$t('msg.address.zip')">
            <el-input v-model="drayOrder.zip" :disabled="drayCost.ctnrid==='FBA'"></el-input>
          </el-form-item>
          <el-button type="text" @click="getAddress()" style="margin-top: 30px" :disabled="drayCost.ctnrid==='FBA'">{{$t('msg.address.importAddress')}}</el-button>
        </el-form>
        <el-form :model="orderFormData" :label-position="orderLabelPosition" inline size="small">
          <el-form-item :label="$t('msg.rate.clientName')" prop="clientName">
            <el-input v-model="orderFormData.clientName" disabled></el-input>
          </el-form-item>
          <el-form-item :label="$t('msg.rate.l3Owner')" prop="l3Owner">
            <el-input v-model="orderFormData.l3Owner" disabled></el-input>
          </el-form-item>
          <el-form-item :label="$t('msg.rate.pageRemark')" prop="pageRemark">
            <el-input v-model="orderFormData.pageRemark"></el-input>
          </el-form-item>
          <el-form-item :label="$t('msg.rate.payTerm')" prop="payTerm">
            <el-input v-model="orderFormData.payTerm" disabled></el-input>
          </el-form-item>
<!--          <el-form-item :label="$t('msg.account.dueDate')" prop="dueDate" >
            <el-date-picker :v-model="orderFormData.dueDate" style="width: 160px" value-format="YYYY-MM-DD"></el-date-picker>
          </el-form-item>-->
        </el-form>
      </el-card>
      <el-divider></el-divider>
      <el-table :data="e2eTrackList" border style="font-size: 12px" size="small">
        <el-table-column prop="mbl" :label="$t('msg.track.mbl')" align="center">
          <template #default="scope">
            <el-input v-model="scope.row.mbl" size="small"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="ctnrId" :label="$t('msg.track.ctnrId')" align="center">
          <template #default="scope">
            <el-input v-model="scope.row.ctnrId" size="small"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="goodsName" :label="$t('msg.track.goodsName')" align="center">
          <template #default="scope">
            <el-input v-model="scope.row.goodsName" size="small"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="danger" :label="$t('msg.track.danger')" align="center" width="75px">
          <template #default="scope">
            <el-select v-model="scope.row.danger" size="small">
              <el-option label="Yes" value="Yes"></el-option>
              <el-option label="No" value="No"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="drayType" :label="$t('msg.track.drayType')" align="center" width="75px">
          <template #default="scope">
            <el-select v-model="scope.row.drayType" size="small">
              <el-option label="Live" value="Live"></el-option>
              <el-option label="Drop" value="Drop"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="ctnrSize" :label="$t('msg.track.ctnrSize')" align="center" width="90px">
          <template #default="scope">
            <el-select v-model="scope.row.ctnrSize" size="small">
              <el-option label="20GP" value="20GP"></el-option>
              <el-option label="40GP" value="40GP"></el-option>
              <el-option label="40HQ" value="40HQ"></el-option>
              <el-option label="45HQ" value="45HQ"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="ctnrKg" :label="$t('msg.track.ctnrKg')" align="center">
          <template #default="scope">
            <el-input-number v-model="scope.row.ctnrKg" size="small" controls-position="right" :min="0" :step="1"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column prop="termLFD" :label="$t('msg.track.termLFD')" align="center">
          <template #default="scope">
            <el-date-picker v-model="scope.row.termLFD" format="YYYY/MM/DD" value-format="YYYY-MM-DD" size="small" style="width: 100%"></el-date-picker>
          </template>
        </el-table-column>
      </el-table>
      <el-divider></el-divider>
      <el-button type="primary" v-on:click="createOrder()" style="float: right;margin-bottom: 10px">{{$t('msg.rate.generateOrder')}}</el-button>
    </el-card>
    <el-dialog title="My Address Book" v-model="addressVisible" width="60%" center draggable>
      <el-button type="primary" style="float: right;margin-bottom: 20px;margin-right: 40px" @click="createAddress()">{{$t('msg.address.newAddress')}}</el-button>
      <el-table :data="myAddress">
        <el-table-column prop="personName" :label="$t('msg.address.personName')" size="small"></el-table-column>
        <el-table-column prop="streetOne" :label="$t('msg.address.streetOne')" size="small"></el-table-column>
<!--        <el-table-column prop="streetTwo" :label="$t('msg.address.streetTwo')"></el-table-column>-->
        <el-table-column prop="city" :label="$t('msg.address.city')" size="small"></el-table-column>
        <el-table-column prop="state" :label="$t('msg.address.state')" size="small"></el-table-column>
        <el-table-column prop="zip" :label="$t('msg.address.zip')" size="small"></el-table-column>
        <el-table-column fixed="right" :label="$t('msg.rate.Operations')" align="center">
          <template #default="scope">
            <el-button link type="text" size="small" @click="importRow(scope.row)">{{$t('msg.address.importAddress')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog title="New Address" v-model="newAddressVisible" width="30%" center draggable>
      <el-form :data="newAddress">
        <el-form-item prop="personName" :label="$t('msg.address.personName')" style="width: 20pc">
          <el-input v-model="newAddress.personName"></el-input>
        </el-form-item>
        <el-form-item prop="streetOne" :label="$t('msg.address.streetOne')" style="width: 20pc">
          <el-input v-model="newAddress.streetOne"></el-input>
        </el-form-item>
        <el-form-item prop="city" :label="$t('msg.address.city')" style="width: 20pc" >
          <el-input v-model="newAddress.city" disabled></el-input>
        </el-form-item>
        <el-form-item prop="state" :label="$t('msg.address.state')" style="width: 20pc">
          <el-select v-model="newAddress.state" placeholder="Select">
            <el-option v-for="item in states" :key="item" :label="item" :value="item" />
          </el-select>
        </el-form-item>
        <el-form-item prop="zip" :label="$t('msg.address.zip')" style="width: 20pc">
          <el-input v-model="newAddress.zip"></el-input>
        </el-form-item>
        <el-button type="primary" style="float: right;margin-bottom: 20px;margin-right: 20px" @click="insertAddress()">Submit</el-button>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { Delete, DocumentAdd, Edit } from '@element-plus/icons-vue'
import axios from 'axios'
import { emptyDrayCostDetail, newQuoteRecord } from '@/constant'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'manuelOrder',
  props: {
    orderTag: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const orderLabelPosition = ref('top')
    const drayOrder = ref({ id: 0, sslCarrier: '', mbl: '', ctnrId: '', ctnrSize: '', sealNumber: 'NA', vesselETA: '', ctnrWeight: 0, wtUOM: 'lb', port: '', streetOne: '', streetTwo: '', city: '', state: '', zip: '', attr: '' })
    const orderFormData = ref({ clientName: '', l3Owner: '', pageRemark: 'NA', payTerm: '', dueDate: '', clientTag: '' })
    const editIcon = Edit
    const delIcon = Delete
    const addIcon = DocumentAdd
    const bookID = ref('')
    const labelPosition = ref('left')
    const addressVisible = ref(false)
    const newAddressVisible = ref(false)
    const myFlag = ref(0)
    const quoteTag = ref('')
    const e2eCommon = ref({ trackingNumber: '', client: '', mbl: '', ctnrId: '', pol: '', pod: '', podRegion: '', ctnrSize: '', ctnrCbm: 0, ctnrKg: 0, cartons: 0, goodsName: '', danger: 'No', swStatus: '', drayType: 'Live', transloadType: '', sslCarrier: '', vessel: '', voyage: '', firmsCode: '', ddray: '', destSite: '', destCode: '', destAddr: '', erAddress: '', dCBPStatus: '', dFreightStatus: '', dTerminalPickupStatus: '', swDeparture: '', swDischarge: '', swFinalArrival: '', swOG: '', swER: '', fullGateIn: '', etd: '', atd: '', eta: '', ata: '', discharge: '', onRail: '', offRail: '', termLFD: '', fullGateOut: '', dWHCrdd: '', dWHArrival: '', dWHEmptyNotify: '', emptyReturnAppt: '', emptyReturn: '', targetOG: '', targetER: '', targetComplete: '', smsId: '' })
    const e2eTrackList = ref([])
    const quoteRecord = ref(newQuoteRecord)
    const portList = ref([])
    const toAreaList = ref([])
    const myAddress = ref([])
    const drayRate = ref([])
    const drayRateAP = ref([])
    const newAddress = ref({ personName: '', streetOne: '', city: '', state: '', zip: '' })
    const drayCost = ref(emptyDrayCostDetail)
    const states = ref([])
    const curUser = localStorage.getItem('curUser')
    const router = useRouter()
    const importRow = (row) => {
      drayOrder.value.streetOne = row.streetOne
      drayOrder.value.city = row.city
      drayOrder.value.state = row.state
      drayOrder.value.zip = row.zip
      addressVisible.value = false
    }
    const createOrder = () => {
      if (e2eCommon.value.sslCarrier === '' || e2eCommon.value.firmsCode === '' || e2eCommon.value.eta === '') {
        alert('Carrier, ETA and FirmsCode are required!')
        return false
      }
      if (orderFormData.value.payTerm.startsWith('ETD') && e2eCommon.value.etd === '') {
        alert('ETD is required because of PayTerm!')
        return false
      } else if (orderFormData.value.payTerm.startsWith('ATD') && e2eCommon.value.atd === '') {
        alert('ATD is required because of PayTerm!')
        return false
      } else if (orderFormData.value.payTerm.startsWith('ATA') && e2eCommon.value.ata === '') {
        alert('ATA is required because of PayTerm!')
        return false
      }
      if (drayOrder.value.streetOne === '' || drayOrder.value.state === '' || drayOrder.value.zip === '') {
        alert('streetOne, state and zip are required!')
        return false
      }
      const destAddr = drayOrder.value.streetOne + ',' + drayOrder.value.streetTwo + ',' + drayOrder.value.city + ',' + drayOrder.value.state + ',' + drayOrder.value.zip + ',USA'
      for (let n = 0; n < e2eTrackList.value.length; n++) {
        if (e2eTrackList.value[n].mbl === '' || e2eTrackList.value[n].ctnrId === '' || e2eTrackList.value[n].goodsName === '' || e2eTrackList.value[n].ctnrKg === 0) {
          alert('MBL, Container ID, GoodsName and Container Weight in Kg are required!')
          return false
        } else {
          e2eTrackList.value[n].trackingNumber = e2eCommon.value.trackingNumber
          e2eTrackList.value[n].pol = e2eCommon.value.pol
          e2eTrackList.value[n].pod = e2eCommon.value.pod
          e2eTrackList.value[n].sslCarrier = e2eCommon.value.sslCarrier
          e2eTrackList.value[n].voyage = e2eCommon.value.voyage
          e2eTrackList.value[n].vessel = e2eCommon.value.vessel
          e2eTrackList.value[n].firmsCode = e2eCommon.value.firmsCode
          e2eTrackList.value[n].etd = e2eCommon.value.etd
          e2eTrackList.value[n].atd = e2eCommon.value.atd
          e2eTrackList.value[n].eta = e2eCommon.value.eta
          e2eTrackList.value[n].ata = e2eCommon.value.ata
          e2eTrackList.value[n].discharge = e2eCommon.value.discharge
          e2eTrackList.value[n].clientName = orderFormData.value.clientName
          e2eTrackList.value[n].ddray = drayCost.value.lspName
          e2eTrackList.value[n].smsId = bookID.value + '-' + e2eTrackList.value[n].ctnrId
          e2eTrackList.value[n].destAddr = destAddr
        }
      }
      if (e2eTrackList.value.length > 1) {
        e2eTrackList.value.sort(function (a, b) {
          return a.ctnrId - b.ctnrId
        })
        for (let n = 1; n < e2eTrackList.value.length; n++) {
          if (e2eTrackList.value[n].smsId === e2eTrackList.value[n - 1].smsId) {
            alert(' Container ID cannot be duplicate!')
            return false
          }
        }
      }
      const param = 'curUser=' + curUser + '&e2eTrackList=' + JSON.stringify(e2eTrackList.value) + '&drayCost=' + JSON.stringify(drayCost.value) +
        '&quoteRecord=' + sessionStorage.getItem('quoteRecord') + '&payTerm=' + orderFormData.value.payTerm
      const reqURL = process.env.VUE_APP_BASE + '/dray/createBatchOrder'
      axios.post(reqURL, param).then((response) => {
        if (response.data === 'Successful') {
          router.push('/record/orders')
        } else {
          alert(response.data)
          return false
        }
      }).catch((response) => {
        console.log(response)
      })
    }
    const getAddress = () => {
      const param = 'curUser=' + curUser + '&city=' + drayCost.value.toArea
      const reqURL = process.env.VUE_APP_BASE + '/getAddressByCity'
      axios.post(reqURL, param).then((response) => {
        myAddress.value = response.data
        addressVisible.value = true
      }).catch((response) => {
        console.log(response)
      })
    }
    const getPayTerm = (val) => {
      const param = 'clientName=' + val
      const reqURL = process.env.VUE_APP_BASE + '/client/getPayTermByClientName'
      axios.post(reqURL, param).then((response) => {
        orderFormData.value.payTerm = response.data
      }).catch((response) => {
        console.log(response)
      })
    }
    const createAddress = () => {
      getStates()
      newAddress.value.city = drayOrder.value.city
      newAddressVisible.value = true
    }
    const getStates = () => {
      axios.get(process.env.VUE_APP_BASE + '/getUS50States').then((response) => {
        states.value = response.data
      })
    }
    const insertAddress = () => {
      if (newAddress.value.personName === '' || newAddress.value.streetOne === '' || newAddress.value.state === '' || newAddress.value.zip === '') {
        alert('Every field in this form is required!')
        return false
      }
      const streetOne = newAddress.value.streetOne.replaceAll(',', ' ')
      const param = 'curUser=' + curUser + '&city=' + newAddress.value.city + '&personName=' + newAddress.value.personName + '&streetOne=' + streetOne +
                   '&state=' + newAddress.value.state.split('-')[1] + '&zip=' + newAddress.value.zip
      const reqURL = process.env.VUE_APP_BASE + '/insertAddress'
      axios.post(reqURL, param).then((response) => {
        myAddress.value = response.data
        newAddressVisible.value = false
      }).catch((response) => {
        console.log(response)
      })
    }
    onMounted(() => {
      quoteTag.value = props.orderTag
      quoteRecord.value = JSON.parse(sessionStorage.getItem('quoteRecord'))
      drayCost.value = JSON.parse(sessionStorage.getItem('drayCost'))
      bookID.value = 'IDF' + quoteRecord.value.csopName + quoteRecord.value.id
      e2eCommon.value.trackingNumber = bookID.value
      e2eCommon.value.pod = drayCost.value.fromArea
      orderFormData.value.clientName = quoteRecord.value.quoteTo
      orderFormData.value.l3Owner = quoteRecord.value.quoteFrom
      for (let n = 0; n < drayCost.value.triAxle; n++) {
        e2eTrackList.value[n] = { trackingNumber: '', client: '', mbl: '', ctnrId: '', pol: '', pod: '', podRegion: '', ctnrSize: '', ctnrCbm: 0, ctnrKg: 0, cartons: 0, goodsName: '', danger: 'No', swStatus: '', drayType: 'Live', transloadType: '', sslCarrier: '', vessel: '', voyage: '', firmsCode: '', ddray: '', destSite: '', destCode: '', destAddr: '', erAddress: '', dCBPStatus: '', dFreightStatus: '', dTerminalPickupStatus: '', swDeparture: '', swDischarge: '', swFinalArrival: '', swOG: '', swER: '', fullGateIn: '', etd: '', atd: '', eta: '', ata: '', discharge: '', onRail: '', offRail: '', termLFD: '', fullGateOut: '', dWHCrdd: '', dWHArrival: '', dWHEmptyNotify: '', emptyReturnAppt: '', emptyReturn: '', targetOG: '', targetER: '', targetComplete: '', smsId: '' }
        e2eTrackList.value[n].ctnrSize = drayCost.value.ctnrType
        e2eTrackList.value[n].pod = drayCost.value.fromArea
      }
      if (drayCost.value.ctnrid === 'FBA') {
        const param = 'fbaCode=' + drayCost.value.toArea
        const reqURL = process.env.VUE_APP_BASE + '/getAddressByFBAcode'
        axios.post(reqURL, param).then((response) => {
          drayOrder.value.streetOne = response.data.streetOne
          drayOrder.value.city = response.data.city
          drayOrder.value.state = response.data.state
          drayOrder.value.zip = response.data.zip
        }).catch((response) => {
          console.log(response)
        })
      } else {
        drayOrder.value.city = drayCost.value.toArea
      }
      getPayTerm(orderFormData.value.clientName)
    })
    return { quoteTag, quoteRecord, bookID, e2eCommon, orderLabelPosition, addIcon, delIcon, editIcon, myFlag, states, addressVisible, e2eTrackList, newAddressVisible, newAddress, getPayTerm, insertAddress, createAddress, getStates, createOrder, labelPosition, orderFormData, myAddress, importRow, portList, toAreaList, drayRate, drayRateAP, drayOrder, drayCost, curUser, getAddress }
  }
})
</script>
